enum EmailTemplateType {
    None = 0,

    // Signup

    BusinessSignedUpButNotPaid2HoursLaterReminder = 100,

    BusinessSignedUpButNotPaid24HoursLaterReminder = 105,

    BusinessSignedUpButNotPaid72HoursLaterReminder = 110,

    VerificationCodeRequestedForMember = 115,


   // Membership Activation

    B2BB2CPackagePaidAndActivatedForMember = 200,

    // StatewidePackagePaidAndActivatedForMember = 205,

    WebsitePackagePaidAndActivatedForMember = 210,

    PromoPackPackagePaidAndActivatedForMember = 215,

    AllAussieExtraPackagePaidAndActivatedForMember = 220,

    MembershipActivationNotificationForWebmaster = 225,

    ExpressSignupInProgressNoticeForMember = 230,
    ExpressSignUpCompleteNoticeForMember = 240,
    ExpressSignUpRequestNoticeForWebmaster = 245,

    WebsiteSetupCompleteAndLiveForMember = 250,

    MembershipAddedAdditionalRegionsForMember = 255,
    MembershipPayLaterInvoice = 260,


    // Membership Renewal

    MembershipRenewalNotice7Days = 300,
    MembershipRenewalNotice21Days = 305,
    MembershipLapsedNotice = 310,
    MembershipRenewedNoticeForMember = 315,
    MembershipCancellationRequestForWebmaster = 320,
    MembershipCancelledNoticeForMember = 325,
    // MembershipPaymentFailed = 330,

    // Quote Requests

    QuoteRequestNotificationForMembers = 400,
    QuoteRequestUnseenFollowUpForMembers48Hours = 405,
    QuoteRequestStatusUpdateForRequester24Hours = 410,
    QuoteRequestFailedToMatchForRequester = 415,

    // Contact us

    ContactUsFormSubmission = 500,
    ContactUsFormSubmissionAcknowledgement = 505,

    // Other Products

    Custom = 600,
    MyWebsiteContactFormSubmissionNotification = 605,
    ClientOffer = 610,
}

export default EmailTemplateType
