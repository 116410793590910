
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import { Config } from 'tradingmate-modules/src/environment'
import { BusinessBranchModel, BusinessBranchStatus } from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import Utils from 'tradingmate-modules/src/Utils'
import { Component, Vue } from 'vue-property-decorator'
import BusinessBranchStatusUpdateDto
  from 'tradingmate-modules/src/models/api/businesses/branches/BusinessBranchStatusUpdateDto'
import EmailTemplateType
  from 'tradingmate-modules/src/models/api/admin/email-templates/EmailTemplateType'
import EmailTemplateSendDto from 'tradingmate-modules/src/models/api/emails/EmailTemplateSendDto'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader
  }
})
export default class MembershipsExpired extends Vue {
  public lapsedMembers: BusinessBranchModel[] | null = null
  private loading = false;

  private isoToDisplayDate = Utils.isoToDisplayDate

  mounted (): void {
    this.getLapsedMembers()
  }

  getLapsedMembers (): void {
    this.loading = true
    Services.API.Admin.Businesses.GetLapsedMembers()
      .then((members) => { this.lapsedMembers = members })
      .finally(() => { this.loading = false })
  }

  onCancel (id: string): void {
    const dto: BusinessBranchStatusUpdateDto = { Status: BusinessBranchStatus.Cancelled }
    Services.API.Admin.Businesses.StatusUpdate(id, dto)
      .then(() => {
        if (!this.lapsedMembers) return false
        const index = this.lapsedMembers.findIndex(x => x.BusinessBranchId === id)
        this.lapsedMembers.splice(index, 1)
      })
  }

  sendingExpirationId = ''
  onExpireNotice (id: string): void {
    const dto: EmailTemplateSendDto = { Type: EmailTemplateType.MembershipLapsedNotice }
    this.sendingExpirationId = id
    Services.API.Admin.Emails.EmailTemplateSend(id, dto)
      .then((_result) => {
        console.log(_result)
      }).finally(() => {
        this.sendingExpirationId = ''
      })
  }

  onRenew (id: string): void {
    this.$router.push({ name: 'Membership', params: { id: id } })
  }

  onViewProfile (slug: string): void {
    const frontendBaseUrl = Config.WebsiteUrls.Public
    const profilePath = `/members/${slug}`
    const url = frontendBaseUrl + profilePath
    window.open(url)
  }

  onEditProfile (id: string): void {
    this.$router.push({ name: 'Business Branch Edit', params: { id: id } })
  }

  onEditWebsite (id: string): void {
    this.$router.push({ name: 'My Website Edit', params: { id: id } })
  }
}

